import React from "react";
import { Helmet } from "react-helmet";
import Container from "../../../components/chat-channel/Container";
import Layout from "../../../components/chat-channel/Layout";
import RightImageWithContentFeature from "../../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../../components/common/Icons";
import { CardsThree } from "../../homepage";

import FooterForm from "../../../components/common/FooterForm";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import TopFormWithImage from "../../../components/TopFormCenterImage";
import {
  autoDesigner,
  chatChannel,
  logs,
  train,
  dialog,
  onprem_icon,
} from "../../conversational-ai-platform/chatbot-builder";
import { RequestForm } from "../../../components/form";
const TopImage = require("../../../assets/img/feature/analytics/analytics_header.png");

export const icon1 = require("../../../assets/img/feature/sec_1_bg.png");
export const icon2 = require("../../../assets/img/feature/sec_2_bg.png");
export const icon3 = require("../../../assets/img/feature/sec_3_bg.png");
export const icon4 = require("../../../assets/img/feature/sec_4_bg.png");

export const cardOne = require("../../../assets/images/homepage/group1.png");
export const cardTwo = require("../../../assets/images/homepage/group4.png");
const section1 = require("../../../assets/img/feature/analytics/analytics_sec_1.png");
const section2 = require("../../../assets/img/feature/analytics/analytics_sec_2.png");
const section3 = require("../../../assets/img/feature/analytics/analytics_sec_3.png");
const section4 = require("../../../assets/img/feature/analytics/analytics_sec_4.png");

const featureContent = [
  {
    icon: icon1,
    header: "Understand user behavior through analytics",
    content:
      "Get a deeper understanding of user behavior and assess the success of chatbot for employees. Track how employees use chatbots. Drill down to the most helpful conversations, duration of communications, most used chat channels, the average number of conversations over a period, and more.",
    image: section1,
  },
  {
    icon: icon2,
    header: "Gauge support satisfaction",
    content:
      "Know how many of your employees find chatbot support to be satisfactory. Use the ‘Feedback’ option to analyze all the positive and negative feedback to help take due action where necessary, and improve the chatbot support experience for your employees.",
    image: section2,
  },
  {
    icon: icon3,
    header: "Usable insights with automation data",
    content:
      "The data from analytics help you identify the number of automation workflows used by employees overall. You would be able to track the most used and the least used automations for a given period, helping you fine-tune chatbot conversations as well.",
    image: section3,
  },
  {
    icon: icon4,
    header: "Track the success and failure of workflow automations",
    content:
      "You can test whether your workflow automations were running smoothly and executed successfully, and in the case of execution failure, you can fix them quickly.",
    image: section4,
  },
];

export const cardData = [
  {
    image: cardOne,
    header: "Workativ for Conversational IT Support",
    content: `Deliver faster and superior IT Support, powered by Conversational AI and Automation. Augment your IT Help Desk to scale your support easily in a few clicks. `,
    content_1: `No coding required.`,
    linkAddress: "/assistant/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for Conversational HR Support",
    content: `Transform HR experience for employees with our AI-powered chatbots with prebuilt HR process automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/conversational-hr-support",
    backgroundClass: "bg_card_2",
  },
];

export const otherFeatures = [
  {
    image: dialog,
    alt: "Dialog Designer",
    header: "Dialog Designer",
    content:
      "Create engaging and guided conversations for your chatbot to respond to end-users.",
    link: "/conversational-ai-platform/chatbot-builder",
  },
  {
    image: autoDesigner,
    alt: "autoDesigner",
    header: "Automation Designer",
    content:
      "Automate IT and HR processes through seamless integration with your existing applications.",
    link: "/conversational-ai-platform/chatbot-automation",
  },
  {
    image: onprem_icon,
    alt: "onprem_icon",
    header: "On-Prem Connect",
    content:
      "Establish a secure connectivity between your on-premise apps and Workativ to automate your workflows.",
    link: "/conversational-ai-platform/on-prem-app-connector",
  },
  {
    image: chatChannel,
    alt: "Chatbot Channel Integration",
    header: "Chatbot Channel Integration",
    content:
      "Deploy the chatbot in a single click on your enterprise chat channel like Teams or Slack.",
    link: "/conversational-ai-platform/chatbot-channels",
  },
  {
    image: logs,
    alt: "Logs",
    header: "Logs",
    content:
      "Track conversations and automation executions to improve chatbot performance.",
    link: "/conversational-ai-platform/featureslogs",
  },
  {
    image: train,
    alt: "Training",
    header: "Training",
    content:
      "Train and continue to improve the chatbot into a full-fledged autonomous support channel for employee support.",
    link: "/conversational-ai-platform/logs-analytics-training",
  },
];

export default function Analytics() {
  return (
    <>
      <TitleAndMetaTags
        title="Chatbot & Automation Analytics and Dashboard | Workativ Assistant Features"
        description="Gather insight to craft better support experiences and deliver better support to your employees."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={TopImage}
        ogTitle="Chatbot & Automation Analytics and Dashboard | Workativ Assistant Features"
        ogDescription="Gather insight to craft better support experiences and deliver better support to your employees."
      />
      <Container>
        <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
          <TopFormWithImage image={TopImage} altImage={"Analytics"}>
            <TopFormWithImage.Header>Analytics</TopFormWithImage.Header>
            <TopFormWithImage.Content>
              Deep analytics and dashboards provide details on how your chatbot
              performs in the real world, and you can use the insights to craft
              a better experience.
            </TopFormWithImage.Content>
            <RequestForm isFooterForm={false} />
          </TopFormWithImage>
          {featureContent.map((feature, index) => {
            const isFeature = index === 0;
            if (index % 2 == 0) {
              return (
                <RightImageWithContentFeature
                  isFeature={isFeature}
                  image={feature.image}
                  altImage={feature.header}
                >
                  <RightImageWithContentFeature.Header>
                    <h3>
                      <img
                        className="icon-header-left"
                        src={feature.icon}
                        alt="header-icon"
                      />
                      <span>{feature.header}</span>
                    </h3>
                  </RightImageWithContentFeature.Header>

                  <RightImageWithContentFeature.SubHeader>
                    <p>{feature.content}</p>
                  </RightImageWithContentFeature.SubHeader>
                </RightImageWithContentFeature>
              );
            }
            return (
              <div className="features_container_left">
                <LeftImageWithContent
                  image={feature.image}
                  altImage={feature.header}
                >
                  <LeftImageWithContent.HeaderIcon>
                    <h3>
                      <img
                        className="icon-header-left"
                        src={feature.icon}
                        alt="header-icon"
                      />
                      <span>{feature.header}</span>
                    </h3>
                  </LeftImageWithContent.HeaderIcon>

                  <LeftImageWithContent.SubHeader>
                    <p>{feature.content}</p>
                  </LeftImageWithContent.SubHeader>
                </LeftImageWithContent>
              </div>
            );
          })}
          <section className="cards_features">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-12 col-sm-12 cards_features_header">
                  <div className="build_needs">
                    <div className="col-lg-12 col-md-12 col-12 p-0 center_features_header">
                      <h2>Tailored to employee needs</h2>
                      <p>
                        Workativ’s one of a kind conversational AI platform,
                        purpose-built for workplace employee support automation,
                        incorporates a very easy-to-use design that requires no
                        coding at all.
                      </p>
                    </div>
                  </div>
                  {otherFeatures.map((feature) => (
                    <div className="col-md-6 col-lg-4 col-12 col-sm-12 col-12 cards_features_header_div">
                      <img src={feature.image} alt={feature.alt} />
                      <h5>{feature.header}</h5>
                      <p>{feature.content}</p>
                      <div className="card_link_landing">
                        <a href={feature.link} className="url_manipulation">
                          Know more{" "}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <CardsThree />
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
